<template>
  <div class="directory-container tw-w-full">
    <zem-card>
      <div>
        <template v-if="editDirectory || newDirectory">
          <zem-card-title
            >{{ newDirectory ? `${titleDirectory} (Новый элемент)` : currentItem['name'] || currentItem['title'] }}
          </zem-card-title>
          <div class="block-id">
            <span class="block-id__label"></span>
            ID {{ newDirectory ? '' : currentItem['number'] }}
          </div>
        </template>
        <template v-else>
          <zem-card-title>{{ currentItem['name'] || currentItem['title'] }}</zem-card-title>
          <div class="block-id">
            <span class="block-id__label"></span>
            ID {{ newDirectory ? '' : '#' + currentItem['real_id'] }}
          </div>
        </template>
        <div v-if="!closeAcc([3])" class="block-id">
          <span class="block-id__label second"></span>
          ID {{ currentItem['id'] }}
        </div>
      </div>
      <div>
        <template v-if="!editDirectory && !newDirectory">
          <img
            v-if="isEdit"
            :src="require('@/assets/icons/edit.svg')"
            alt=""
            class="block-id__edit"
            @click="editDirectory = true"
          />
          <img :src="require('@/assets/icons/close.svg')" alt="" class="block-id__delete" @click="closeRightSidebar" />
        </template>
        <div v-else class="tw-flex tw-gap-x-2">
          <zem-link class="tw-flex tw-items-center tw-gap-x-2" @click="closeRightSidebar">
            <img :src="require('@/assets/icons/close.svg')" alt="" class="block-id__edit mobile-d tw-h-[20px]" />
            <span class="mobile-d-none">Отменить</span>
          </zem-link>
          <zem-button :disabled="isSavedDirectory" :loading="isSavedDirectory" @click="saveItem">
            <img
              v-if="!isSavedDirectory"
              :src="require('@/assets/icons/check.svg')"
              alt=""
              class="block-id__edit mobile-d tw-h-[13px]"
            />
            <span class="mobile-d-none">Сохранить</span>
          </zem-button>
        </div>
      </div>
    </zem-card>
    <zem-card class="details-form mt-3">
      <template>
        <div v-for="(field, index) in itemsArray(currentItem)" :key="index" class="details-form__info-container">
          <div class="details-form__title-block">{{ $t(field) }}</div>
          <div
            v-if="
              !editDirectory &&
              !newDirectory &&
              ['be_confirmed', 'be_closed', 'consider_other', 'multi_day'].includes(field)
            "
            class="details-form__data-block"
          >
            <ZemCheckbox :value="currentItem[field]" disabled />
          </div>
          <div v-else-if="!editDirectory && !newDirectory" class="details-form__data-block">
            {{ valueItem(field) }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <template v-if="field === 'brand'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="options"
                @input="currentItem[field] = $event"
                @open="getOptions('/v1/directory/brands', index)"
              />
            </template>
            <template v-else-if="field === 'unit'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="options"
                @input="currentItem[field] = $event"
                @open="getOptions('/v1/units', index)"
              />
            </template>
            <template v-else-if="field === 'type'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="['text']"
                type="string"
                @input="currentItem[field] = $event"
              />
            </template>
            <template v-else-if="field === 'region'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="options"
                @input="currentItem[field] = $event"
                @open="getOptions('/v1/regions', index)"
              />
            </template>
            <template v-else-if="field === 'country'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="options"
                @input="currentItem[field] = $event"
                @open="getOptions('/v1/countries', index)"
              />
            </template>
            <template v-else-if="field === 'pipeline'">
              <ZemDropdownList
                v-model="currentItem[field]"
                :loading="loading"
                :options="options"
                @input="currentItem[field] = $event"
                @open="getOptions('/v1/pipeline', index)"
              />
            </template>
            <template v-else-if="field === 'property_unit_list'">
              <ZemMultiSelect
                v-model="currentItem[field].data"
                :loading="loading"
                :options="options"
                @open="getOptions('/v1/units', index)"
              />
            </template>
            <template v-else-if="['be_confirmed', 'be_closed', 'consider_other', 'multi_day'].includes(field)">
              <div class="tw-h-[26px] tw-flex tw-flex-col tw-justify-center">
                <ZemCheckbox v-model="currentItem[field]" />
              </div>
            </template>
            <template v-else-if="['time_size'].includes(field)">
              <ZemInput :value="currentItem[field]" class="m-0" type="number" @input="inputField($event, field)" />
            </template>
            <template v-else>
              <ZemInput :value="currentItem[field]" class="m-0" type="text" @input="inputField($event, field)" />
            </template>
          </div>
        </div>
        <div v-for="param in currentItem['params_schema']" :key="param.id" class="details-form__info-container">
          <div v-tippy="{content: param.hit}" class="details-form__title-block truncate-text">{{ param.title }}</div>
          <div
            v-if="!editDirectory && !newDirectory"
            class="details-form__data-block tw-flex tw-items-center tw-gap-x-1"
          >
            <div
              v-if="param.key === 'color' && param.value"
              :style="{backgroundColor: param.value}"
              class="tw-w-4 tw-h-4 tw-rounded tw-border"
            ></div>
            {{ param.value }}
          </div>
          <div v-else class="details-form__data-block m-0">
            <div v-if="param.key === 'color'" class="tw-flex tw-items-center tw-gap-x-2">
              <ZemInput :value="param.value" class="m-0" type="text" @input="param.value = $event" />
              <ColorPicker v-model="param.value" />
            </div>
            <ZemInput v-else :value="param.value" class="m-0" type="text" @input="param.value = $event" />
          </div>
        </div>
      </template>
    </zem-card>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import DirectoryService from '@/services/directory.service'
import {closeAcc, errorHandler} from '@/assets/scripts/scripts'
import ZemSelect from '@/components/ui/Select.vue'
import OtherService from '@/services/other.service'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import {eventBus} from '@/main'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ColorPicker from '@/components/ui/ColorPicker.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'

export default {
  components: {
    ZemCheckbox,
    ColorPicker,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    newElement: {
      type: Object,
      default: () => {},
    },
    newDirectory: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    titleDirectory: {
      type: String,
      default: '',
    },
    includesArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editDirectory: false,
      newElementTitle: '',
      options: [],
      loading: false,
      isSavedDirectory: false,
    }
  },
  computed: {
    test() {
      return this.itemsArray(this.currentItem)
    },
  },
  watch: {
    titleDirectory() {
      this.newElementTitle = ''
    },
  },
  mounted() {
    eventBus.$on('new-edit-state-directory', data => {
      this.editDirectory = data
    })
  },
  methods: {
    closeAcc,
    async saveItem() {
      this.isSavedDirectory = true

      let data = {}

      const {path, includes, accessName} = this.$store.state.directory.selectedDirectory

      this.itemsArray(this.currentItem).forEach(el => {
        if (['brand', 'region', 'country', 'unit'].includes(el)) {
          const fieldName = el + '_id'
          data[fieldName] = this.currentItem[el].value || this.currentItem[el].id
        } else if (el === 'property_unit_list') {
          data['units'] = this.currentItem[el].data.map(el => {
            return el.value
          })
        } else if (el === 'params') {
          data['params'] = {}
        } else if (['be_confirmed', 'be_closed', 'consider_other', 'multi_day'].includes(el)) {
          data[el] = !!this.currentItem[el] ? 1 : 0
        } else {
          data[el] = this.currentItem[el]
        }
      })

      if (Object.hasOwn(this.currentItem, 'params_schema')) {
        data['params'] = {}
        this.currentItem['params_schema'].forEach(el => {
          data['params'][el.key] = el.value
        })
      }

      if (this.newDirectory) {
        await DirectoryService.createDirectory(path, data)
          .then(async () => {
            this.$toast.success('Элемент списка создан')
            // await DirectoryService.getDirectory(path, includes)
            this.$emit('update-data')
            this.$store.commit('sidebars/changeRightSidebar', false)
            this.$store.commit('directory/resetSelectedRows')
            this.$emit('on-new-close')
            this.isSavedDirectory = false
            await this.$router.push({name: 'directory-type', params: {type: accessName}})
          })
          .catch(e => {
            errorHandler(e)
          })
          .finally(() => {
            this.isSavedDirectory = false
          })
      } else {
        await DirectoryService.editDirectory(this.currentItem.id, path, data)
          .then(async () => {
            this.$toast.success('Элемент списка обновлен')
            // await DirectoryService.getDirectory(path, includes)
            this.$emit('update-data')
            this.$store.commit('sidebars/changeRightSidebar', false)
            this.$store.commit('directory/resetSelectedRows')
            this.editDirectory = false
            this.isSavedDirectory = false
            await this.$router.push({name: 'directory-type', params: {type: accessName}})
          })
          .catch(e => {
            errorHandler(e)
          })
          .finally(() => {
            this.isSavedDirectory = false
          })
      }

      this.editDirectory = false
    },
    itemsArray(data) {
      const arr = Object.keys(data)
      return arr.filter(item => !this.includesArray.includes(item))
    },
    valueItem(data) {
      if (typeof this.currentItem[data] === 'object') {
        if (data === 'property_unit_list') {
          return this.currentItem[data].data
            .map(el => {
              return el.title
            })
            .join(', ')
        } else if (['brand', 'country', 'region'].includes(data)) {
          return this.currentItem[data].title
        } else if (['unit'].includes(data)) {
          return this.currentItem[data].data.name
        } else {
          return ''
        }
      } else {
        return this.currentItem[data]
      }
    },
    async getOptions(path) {
      this.options = []
      this.loading = true
      await OtherService.getOptions(path).then(r => {
        this.options = r.data.data
        this.loading = false
      })
    },
    inputField(event, field) {
      typeof this.currentItem[field] !== 'object'
        ? (this.currentItem[field] = event)
        : (this.currentItem[field].data.title = event)
    },
    closeRightSidebar() {
      this.editDirectory = false
      eventBus.$emit('new-state', false)
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.directory-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      img:not(:first-child) {
        margin-left: 8px;
      }

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.directory-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      margin-right: 5px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
